import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import { GatsbyImage } from "gatsby-plugin-image"

export default ({ data }) => {
  const page = data.wpPage
  return (
    <><Header />
    <section className="section">
    <article className="container">
      
      <h1 className="is-size-2">{page.title}</h1>

      <div className="content" dangerouslySetInnerHTML={{ __html: page.content }} />
      
    </article>
    </section>
    <Footer />
    </>
    

  )
}

export const query = graphql`
  query($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      title
      content
    }
  }
`